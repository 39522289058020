@import "../../assets/variableStyles.scss";

/* Calander component custom styles */
@include mobile {
  .react-datepicker.floating {
    width: 100%;
    .react-datepicker-container {
      width: 100%;
    }
  }
}

.hotels_search_box {
  background-color: transparent;
  padding: 25px 20px;
  border-radius: 5px;
  @include mobile {
    padding: 10px !important;
  }
}
.hotel-search-form {
  .ant-form-item {
    flex-wrap: wrap !important;
    align-items: flex-start;
    @include mobile {
      margin-bottom: 5px;
    }
    label {
      margin-bottom: 0 !important;
    }
  }
  .hotel-autocomplete {
    flex-direction: column;
    .ant-form-item-control {
      width: 100%;
    }
  }
  .ant-form-item-label {
    label {
      font-size: 11px;
      font-weight: 700;
      color: #1c2b39;
      height: 20px;
      display: none;
    }
  }
}
.hotel-search-form {
  align-items: flex-end;
}
.add-room-block {
  position: relative;
}
.pax-modal {
  position: absolute;
  z-index: 1;
  left: -25px;
  background: #fff;
  width: 280px;
  margin-top: 10px;
  max-height: 500px;
  margin-top: 10px;
  overflow: auto; 
  border: 1px solid #bcd5f5;

  .pax-modal-wrapper {
    padding: 10px 20px;
    // border: 1px solid #bcd5f5;
    position: relative;
    .pax-modal-arrow {
      content: "";
      border-left: 10px solid transparent;
      border-right: 9px solid transparent;
      border-bottom: 10px solid #bcd5f5;
      position: absolute;
      top: -10px;
      left: 45%;
      @include mobile {
        left: 20%;
      }
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 9px solid #fff;
        border-top: 0px solid #fff;
        left: -9px;
        top: 2px;
      }
    }

    ul.first-item {
      padding: 0;
      margin: 0;
      width: 100%;

      .hotel-pax-box {
        &:first-child {
          p.remove-btn {
            display: none;
          }
        }
        li {
          &:last-child {
            border-bottom-color: transparent;
          }

          ul.child-item {
            width: 100%;
            li {
              .lists-wrapper {
                display: flex;
                justify-content: space-between;
                list-style-type: none;
                border-bottom: 1px solid #dcdee3;
                margin-bottom: 2px;
                margin-top: 8px;
                padding-bottom: 8px;
                .roomtitle {
                  font-size: 16px;
                  font-weight: bold;
                  color: #284681;
                  margin-bottom: 0;
                }

                p.remove-btn {
                  font-size: 12px;
                  color: #0775e2;
                  cursor: pointer;
                  margin-bottom: 0;
                  &:hover {
                    text-decoration: underline;
                  }
                }
                .pax-label {
                  flex-grow: 1;
                  p {
                    margin-bottom: -6px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #1c2b39;
                  }
                  span {
                    font-size: 12px;
                    font-weight: 400;
                    color: #969dac;
                  }
                }
                .pax-count {
                  flex-grow: 1;
                  align-self: baseline;
                  display: flex;
                  justify-content: space-evenly;
                  .fa {
                    background: #0775e2;
                    padding: 4px;
                    color: #fff;
                    line-height: 1;
                    vertical-align: sub;
                    cursor: pointer;
                    border-radius: 10px;
                  }
                }
              }
              .agetitle {
                font-size: 12px;
                font-weight: 400;
                color: #969dac;
                margin: 4px 0;
              }
            }
          }
        }
      }
    }

    .add-room-btn {
      color: #0775e2;
      font-size: 12px;
      display: inline;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    .pax-ready-btn {
      border-radius: 3px;
      background: #fff;
      padding: 6px;
      color: #0775e2;
      font-size: 11px;
      font-weight: 700;
      border: 2px solid #0775e2;
      margin-top: 20px;
      height: auto;
      &:hover {
        background: #0775e2;
        color: #fff;
      }
    }
  }
  @include mobile {
    left: 0;
    width: 100%;
    margin-top: 10px;
  }
}

.pax-modal::-webkit-scrollbar{
  width: 10px;
}

.pax-modal::-webkit-scrollbar-thumb {
  background-color: #888;   
  border-radius: 10px;     
  height: 10px;
}

.pax-modal::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Set the background color of the scrollbar track */
}

.hotels-wrapper {
  .ant-radio-group {
    margin-bottom: 10px;
    @include mobile {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    .ant-radio-wrapper {
      @include mobile {
        width: 100%;
      }
    }
  }
}

.accordion {
  margin-top: 0px;
  .card {
    border: none;
    margin-bottom: 0px;
    overflow: visible !important;
    h2 {
      background: url(https://cdn0.iconfinder.com/data/icons/entypo/91/arrow56-512.png)
        no-repeat calc(100% - 10px) center;
      background-size: 20px;
      cursor: pointer;
      font-size: 18px;
      &.collapsed {
        background-image: url(https://cdn0.iconfinder.com/data/icons/arrows-android-l-lollipop-icon-pack/24/expand2-256.png);
      }
    }
    &-body {
      padding: 0;
    }
  }
}
.hotels-search-card-value {
  background: transparent !important;
}

.hide-icon-heading {
  display: none;
}

/*csss--new--sec--*/
.hotel-bg-panel-all {
  .ant-form-item {
    margin-bottom: 0px !important;

    .ant-select-lg {
      border-right: 1px solid #d6d3d3;

      @include mobile {
        border-right: 0px;
        border-bottom: 1px solid #d6d3d3;
      }
      .ant-select-arrow {
        display: none;
      }

      .ant-select-selector {
        height: 65px !important;
        padding: 14px 11px;
        border-color: transparent !important;
        border: 0px solid !important;
        border-right-width: 0px !important;
        border-radius: 0;
        color: #bfbfbf;

        .ant-select-selection-search-input {
          color: black;
          height: 65px !important;
        }
        .ant-select-selection-placeholder {
          border: 0;
          background: none;
          font-weight: 600;
          font-size: 15px;
          outline: medium none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000;
        }

        .ant-select-selection-item {
          border: 0;
          background: none;
          font-weight: 600;
          font-size: 15px;
          outline: medium none;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: #000;
        }
      }
    }
    .ant-input-lg {
      height: 65px;
      padding: 11px 11px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      border-radius: 0;
      color: #000;
    }

    .ant-picker-large {
      padding: 11px 11px 6.5px !important;
      height: 65px;
      border: none;
      border-right: 1px solid #d6d3d3 !important;
      border-radius: 0;
      color: #bfbfbf;

      @include mobile {
        border-right: 0px;
        border-bottom: 1px solid #d6d3d3;
      }
      .ant-picker-input {
        input {
          color: #000;
          font-weight: 600;
        }
      }
      .ant-picker-input input::placeholder {
        color: black;
      }
    }

    .ant-form-item-explain.ant-form-item-explain-error {
      color: #fff !important;
    }
  }
  .ant-btn {
    width: 100%;
    height: 65px;
    border-radius: 0 2px 2px 0;
    @include mobile {
      border-radius: 2px;
    }
  }
}
