.travel-ticket-iti {
  text-align: center;
}

.travel-ticket-iti .person-img {
  height: 150px;
}
.travel-ticket-iti p {
  margin-bottom: 0px;
}
.travel-iti-heading {
  padding-top: 15px;
}
.travel-ticket-iti .round-ban {
  height: 400px;
  margin-top: 35px;
  margin-bottom: 35px;
}
.create-itineary {
  font-size: 17px;
  font-weight: 600;
}

.hotel-tag-1 {
  width: 100%;
}
.hotel-list-para {
  padding-left: 5px;
  margin-bottom: 0;
  margin-top: 4px;
}
.hotel-list-prime li {
  display: inline-block;
}
.wt-hotel,
.wt-hotel2 {
  line-height: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}
.wt-hotel {
  width: 140px;
  font-size: 13px;
}

.wt-hotel2 {
  width: 450px;
  font-size: 14px;
  text-align: right;
}
.wt-activity,
.wt-activity2 {
  line-height: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}
.wt-activity {
  font-size: 13px;
}

.wt-activity2 {
  font-size: 13px;
  text-align: right;
}
.wt-activity3 {
  margin-top: -25px;
  margin-right: 25px;
  color: #2f5a87;
  text-align: right;
 }
.hotel-list-prime {
  padding-top: 5px;
}

.timeline {
  position: relative;
  padding: 20px 0;
  list-style: none;
  max-width: 1200px;
  margin: 0 auto;
}
.timeline:before {
  content: " ";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 3px;
  margin-left: -1.5px;
  background-color: #e9ecef;
}
.timeline > .timeline-item {
  position: relative;
  margin-bottom: 20px;
}
.timeline > .timeline-item:after,
.timeline > .timeline-item:before {
  content: " ";
  display: table;
}
.timeline > .timeline-item:after {
  clear: both;
}
.timeline > .timeline-item > .timeline-panel {
  float: left;
  position: relative;
  width: 46%;
  padding: 20px;
  border: 1px solid #e9ecef;
  border-radius: 2px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.05);
}
.timeline > .timeline-item > .timeline-panel:before {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 26px;
  right: -8px;
  border-color: transparent #e9ecef;
  border-style: solid;
  border-width: 8px 0 8px 8px;
}
.timeline > .timeline-item > .timeline-panel:after {
  content: " ";
  display: inline-block;
  position: absolute;
  top: 27px;
  right: -7px;
  border-color: transparent #fff;
  border-style: solid;
  border-width: 7px 0 7px 7px;
}
.timeline > .timeline-item > .timeline-badge {
  z-index: 10;
  position: absolute;
  top: 0px;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  border-radius: 50% 50% 50% 50%;
  text-align: center;
  font-size: 12px;
  line-height: 40px;
  color: #fff;
  overflow: hidden;
}
.timeline > .timeline-item.timeline-inverted > .timeline-panel {
  float: right;
}
.timeline > .timeline-item.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -8px;
  border-right-width: 8px;
  border-left-width: 0;
}
.timeline > .timeline-item.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -7px;
  border-right-width: 7px;
  border-left-width: 0;
}
.timeline-badge.primary {
  background-color: #7460ee;
}
.timeline-badge.success {
  background-color: #39c449;
}
.timeline-badge.warning {
  background-color: #ffbc34;
}
.timeline-badge.danger {
  background-color: #f62d51;
}
.timeline-badge.info {
  background-color: #009efb;
}
.timeline-title {
  margin-top: 0;
  color: inherit;
  font-weight: 400;
}
.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}
.timeline-left:before {
  left: 30px;
}
.timeline-left > .timeline-item > .timeline-badge {
  left: 30px;
  top: 9px;
}
.timeline-left > .timeline-item > .timeline-panel {
  width: calc(100% - 80px);
}
.timeline-right:before {
  right: 30px;
  left: auto;
}
.timeline-right > .timeline-item > .timeline-badge {
  right: 5px;
  top: 9px;
  left: auto;
}
.timeline-right > .timeline-item > .timeline-panel {
  width: calc(100% - 80px);
}
.card-hotel-book {
  margin-top: 30px;
}
.user-hotel-data li {
  list-style: inside;
}
.end-travel-trip {
  text-align: center;
  font-size: 18px;
  color: #ea1b37;
  margin-top: 25px;
}
.hotel-tag-travel {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@media only screen and (max-width: 768px) {
  .timeline-left > .timeline-item > .timeline-panel {
    width: calc(100% - 58px);
  }
  .timeline > .timeline-item > .timeline-panel {
    padding: 0px 5px;
  }
  .hotel-list-para {
    padding-left: 0px !important;
  }
}




// .car-barcode-act .slick-dots {
//   bottom: -10px !important; /* Moves the dots below */
// }

// .car-barcode-act .slick-dots li button {
//   background: #989797 !important; /* Light gray dots */
//   border-radius: 50%;
//   width: 10px;
//   height: 10px;
// }

// .car-barcode-act .slick-dots li.slick-active button {
//   background: #1890ff !important; /* Active dot color */
// }

// .car-barcode-act .slick-dots li button::before{
//   display: none;
// }

// .car-barcode-act .custom-prev-arrow,
// .car-barcode-act .custom-next-arrow {
//   position: absolute;
//   top: 50%;
//   transform: translateY(-50%);
//   font-size: 24px;
//   color: white;
//   background: rgba(0, 0, 0, 0.5);
//   border-radius: 50%;
//   width: 40px;
//   height: 40px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   cursor: pointer;
//   z-index: 10;
// }

// .car-barcode-act .custom-prev-arrow {
//   left: -50px; /* Adjust for positioning */
// }

// .car-barcode-act .custom-next-arrow {
//   right: -50px;
// }

// .car-barcode-act .custom-prev-arrow:hover,
// .custom-next-arrow:hover {
//   background: rgba(0, 0, 0, 0.8);
// }



.car-barcode-act {
  position: relative;
  margin: 30px 0px 40px 0px;
}

/* Style for left (previous) arrow */
.car-barcode-act .slick-prev {
  left: -50px; /* Adjust if needed */
  z-index: 10;
}

/* Style for right (next) arrow */
.car-barcode-act .slick-next {
  right: -50px; /* Adjust if needed */
  z-index: 10;
}

/* General styles for arrows */
.car-barcode-act .slick-prev,
.car-barcode-act .slick-next {
  position: absolute;
  bottom: 50%;
  transform: translateY(-50%);
  font-size: 24px;
  // color: white;
  color: black !important;
  // background: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Change arrow background on hover */
.car-barcode-act .slick-prev:hover,
.car-barcode-act .slick-next:hover {
  // background: rgba(0, 0, 0, 0.8);
}

/* Ensure arrow icons are visible */
.car-barcode-act .slick-prev:before{
  content: "‹";
  position: absolute;
  font-size: 24px;
  color: white !important;
  bottom: -70px;
  left: 140%;
  background-color: #7460ee;
  border-radius: 50%;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  transition: background 0.3s ease, transform 0.3s ease;
  transform-origin: center;
}

.car-barcode-act .slick-next:before {
  content: "›";
  position: absolute;
  font-size: 24px;
  color: white !important;
  bottom: -70px;
  right: 140%;
  background-color: #7460ee;
  padding: 4px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  line-height: 1;
  transition: background 0.3s ease, transform 0.3s ease;
  transform-origin: center;
}

.car-barcode-act .slick-prev:hover:before {
  background: #5a42d1; 
  transform: scale(1.1);
  cursor: pointer;
}

.car-barcode-act .slick-next:hover:before {
  background: #5a42d1; 
  transform: scale(1.1); 
  cursor: pointer;
}